<template>
  <SolidLayout>
    <template slot="main-side">
      <article>
        <header>
          <h1>ASAP is a swear word</h1>
        </header>
        <section>
          <p>
            The <a href="https://www.urbandictionary.com/define.php?term=asap">ASAP</a> dates back to the army commands in WWI and was (and, unfortunately, still is) widely used in corporate management culture.
            This article is to highlight why it should have been phased out long ago already, and the approach that works much better to prioritise solving specific situations.
          </p>

          <p>
            Resorting to the usage of ASAP in professional communication is similar to resorting to violence when resolving problems in personal communication - it's a tool that should never be used, yet it's really easy to use and thus can be appealing.
            It's an imperative top-down application of unquestionable urgency that doesn't allow any space for discussion.
            It also comes hand-in-hand with the mentality of "bringing the solutions" management approach, when managers are telling people what to do and often also how to do it, hence when something doesn't go according to plan, addressing the issue also becomes an imperative command instead of raising and relying on employees' situational awareness.
          </p>

          <p>
            Using ASAP is essentially recognising that things didn't go according to plan but there's no interest in talking about it or addressing the root cause, and instead a forceful demand to "get your head down and fix it! ASAP!!"
          </p>

          <p>
            There are much better ways of getting things done quickly, such as helping employees to understand the project plans and problems, maintain open and two-way communication and feedback channel and share the decisions, plans, problems and their background, so that everyone could relate to the product and act on solving problems vast on their own volition, and recognise and address the priorities.
            Apart from making people more informed and thus making more relevant decision when building the product, it also raises the responsibility and passion for the things they do, for the product they build and a loyalty to the company - while telling people to do things ASAP does the very opposite.
          </p>

          <p>
            So no ASAP, please. Let's raise awareness and prioritise things together instead.
          </p>
        </section>
      </article>
    </template>
  </SolidLayout>
</template>

<script>
import SolidLayout from "@/components/SolidLayout.vue";

export default {
  name: "AgainstASAP",
  components: {SolidLayout}
}
</script>

<style scoped>

</style>